<template>
  <div>
    <b-card no-body class="mb-0">
      <!-- title="Gestion des Ressources Humaines" -->
      <b-tabs>
        <b-tab active>
          <template #title>
            <feather-icon icon="ListIcon" />
            <span>Liste des Mouvements</span>
          </template>

          <b-card-text>
            <!-- Table Container Card -->
            <b-card no-body class="mb-0">
              <div class="m-2">
                <!-- Table Top -->
                <b-row>
                  <!-- Per Page -->
                  <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0 pull-left">
                    <label>Afficher</label>
                    <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
                    <label>Elements par page</label>
                  </b-col>

                  <!-- Search -->
                  <b-col cols="12" md="8">
                    <div class="d-flex align-items-center justify-content-end pull-right">
                      <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Rechercher..." />
                      <b-button variant="primary" :to="{ name: 'presence-add-new' }">
                        <!-- @click="isAddNewDataSidebarActive = true" -->
                        <span class="text-nowrap">Enregistrer Mouvement</span>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </div>

              <b-table id="tableFichePresence" ref="refDataListTable" class="position-relative"
                :items="fetchModulePresences" responsive :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy"
                show-empty empty-text="Aucun resultat trouvé" :sort-desc.sync="isSortDirDesc">
                <template #cell(direction)="data">
                  <div class="text-nowrap">
                    <feather-icon :icon="resolvePresenceIcon(data.item.direction)" size="18" class="mr-50" :class="`text-${resolvePresenceVariant(
                      data.item.direction
                    )}`" />
                    <span class="align-text-top text-capitalize">{{
                      data.item.direction
                    }}</span>
                  </div>
                </template>

                <!-- Column: Status -->
                <template #cell(status)="data">
                  <b-badge pill :variant="`light-${resolveDataStatusVariant(
                    data.item.status
                  )}`" class="text-capitalize">
                    {{ data.item.status }}
                  </b-badge>
                </template>

                <!-- Column: Agent -->
                <template #cell(agent)="data">
                  {{ data.item.agent.firstname }} {{ data.item.agent.lastname }}
                </template>

                <!-- Column: Created_at -->
                <template #cell(created_at)="data">
                  {{ formatDate(data.item.created_at) }}
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                  <div class="text-nowrap">
                    <feather-icon :id="`presence-row-${data.item.id}-preview-icon`" icon="EyeIcon" size="16" class="mx-1"
                      @click="
                        $router.push({
                          name: 'presence-view',
                          params: { id: data.item.id },
                        })
                        " />
                    <b-tooltip title="Visionner Presence" :target="`presence-row-${data.item.id}-preview-icon`" />
                  </div>
                </template>
              </b-table>
              <div class="mx-2 mb-2">
                <b-row>
                  <b-col cols="12" sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start">
                    <span class="text-muted">Affiché {{ dataMeta.from }} à {{ dataMeta.to }} sur
                      {{ dataMeta.of }} Enregistements</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                    <b-pagination v-model="currentPage" :total-rows="totalDatas" :per-page="perPage" first-number
                      last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-card-text>
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon icon="FileIcon" />
            <span>Fiche de Presence</span>
          </template>
          <b-card-text>
            <b-row class="container">
              <b-col cols="12" md="2">
                <!-- year -->
                <b-form-group label="Année" label-for="year">
                  <b-form-input id="year" v-model="presenceData.year" trim class="" />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="2">
                <!-- month -->
                <b-form-group label="Mois" label-for="type">
                  <v-select v-model="presenceData.month" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="monthOptions" :reduce="(val) => val.value" :clearable="false" input-id="month" />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="3">
                <!-- agent -->
                <b-form-group label="Agent" label-for="sku_agent">
                  <v-select v-model="presenceData.sku_agent" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="agentOptions" :reduce="(val) => val.value" :clearable="false" input-id="sku_agent" />
                </b-form-group>
              </b-col>

              <b-col cols="6" md="2">
                <!-- agent -->
                <label for=""></label>
                <b-form-group label="" label-for="sku_agent">
                  <b-button variant="primary" @click="loadPresenceData">
                    <span class="text-nowrap">Recupérer Fiche</span>
                  </b-button>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="2">
                <!-- agent -->
                <label for=""></label>
                <b-form-group label="" label-for="sku_agent">
                  <b-button variant="success" @click="printPresenceFile('printableArea')">
                    <span class="text-nowrap">Imprimer</span>
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
            <div id="printableArea">
              <b-table-simple hover caption-top responsive class="rounded-bottom mb-0">
                <b-thead head-variant="light">
                  <b-tr>
                    <b-th colspan="3"> Jours Feriés </b-th>
                    <b-th colspan="3"> </b-th>
                    <b-th colspan="2"> Détails </b-th>
                  </b-tr>
                  <b-tr>
                    <b-th>Jour</b-th>
                    <b-th>Date</b-th>
                    <b-th>Arrivée</b-th>
                    <b-th>Départ</b-th>
                    <b-th>Mode (Mvmts)</b-th>
                    <b-th>Nbre Heures</b-th>
                    <b-th>Notes du Superviseur</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody v-if="presenceDataLoaded == true">
                  <b-tr v-for="(item, jour) in fichePresence" :key="jour">
                    <!-- <b-th rowspan="3"> {{ item.jour }}  </b-th> -->
                    <!-- <b-td variant="success"> 72 </b-td> -->
                    <b-th class="text-right">
                      <span v-if="!item.weekend"> {{ item.jour }}</span>
                      <span v-else>-</span>
                    </b-th>
                    <b-td :variant="`${item.weekend ? 'warning' : ''}`">{{
                      item.date
                    }}</b-td>
                    <b-td>{{ item.start }}</b-td>
                    <b-td>{{ item.stop }}</b-td>
                    <b-td>{{ item.auth_mode }}</b-td>
                    <b-td>{{ item.sum_hours }}</b-td>
                    <b-td>{{ item.supervisor_note }}</b-td>
                  </b-tr>
                  <!-- <b-tr>
                  <b-th class="text-right"> Gent </b-th>
                  <b-td>46</b-td>
                  <b-td variant="warning"> 18 </b-td>
                  <b-td>50</b-td>
                  <b-td>61</b-td>
                  <b-td>01</b-td>
                  <b-td variant="danger"> 15 </b-td>
                </b-tr>
                <b-tr>
                  <b-th class="text-right"> Brussels </b-th>
                  <b-td>51</b-td>
                  <b-td>27</b-td>
                  <b-td>28</b-td>
                  <b-td>38</b-td>
                  <b-td>69</b-td>
                  <b-td>28</b-td>
                </b-tr>
                <b-tr>
                  <b-th rowspan="2"> The Netherlands </b-th>
                  <b-th class="text-right"> Amsterdam </b-th>
                  <b-td variant="success"> 89 </b-td>
                  <b-td>34</b-td>
                  <b-td>69</b-td>
                  <b-td>85</b-td>
                  <b-td>38</b-td>
                </b-tr>
                <b-tr>
                  <b-th class="text-right"> Utrecht </b-th>
                  <b-td>80</b-td>
                  <b-td variant="danger"> 12 </b-td>
                  <b-td>43</b-td>
                  <b-td>36</b-td>
                  <b-td>50</b-td>
                  <b-td variant="warning"> 19 </b-td>
                </b-tr> -->
                </b-tbody>
                <b-tbody>
                  <b-tr style="border-bottom: 1px solid black; padding-top: 15px">
                    <b-td colspan="4" variant="secondary" class="text-left">
                      <h1> Date: <b> {{ date_time }}</b> </h1>
                    </b-td>
                    <b-td colspan="2" variant="secondary" class="text-right">
                      <h1> Heures Prestés: <b> {{ sumHours }}</b> </h1>
                    </b-td>
                    <b-td colspan="2" variant="secondary" class="text-right">
                      <h1> Jours Prestés: <b> {{ sumDays }}</b> </h1>
                    </b-td>
                  </b-tr>
                </b-tbody>
                <b-tbody v-if="presenceDataLoaded == false">
                  <b-tr aria-colspan="3" rowspan="2" class="text-warning">
                    <b-td>Attente de selection...</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>
          </b-card-text>
          <!-- <b-card-text
            >Tiramisu carrot cake marzipan sugar plum powder marzipan sugar plum
            bonbon powder. Macaroon jujubes ice cream sugar plum lollipop wafer
            caramels. Cheesecake chocolate tart cake gingerbread fruitcake cake
            candy jelly-o. Candy cookie lollipop. Wafer lemon drops chocolate
            cake gummi bears.</b-card-text
          > -->
        </b-tab>
        <b-tab disabled>
          <template #title>
            <feather-icon icon="BarChartIcon" />
            <span>Evaluation de Performance</span>
          </template>
          <b-card-text>
            Biscuit macaroon sugar plum sesame snaps oat cake halvah fruitcake
            pudding cotton candy. Cheesecake tart wafer soufflé. Chocolate
            marzipan donut pie soufflé dragée cheesecake. Gummi bears dessert
            croissant chocolate jujubes fruitcake. Pie cupcake halvah.
          </b-card-text>
          <b-card-text>
            <!-- Tiramisu carrot cake marzipan sugar plum powder marzipan sugar plum
            bonbon powder. Macaroon jujubes ice cream sugar plum lollipop wafer
            caramels. Cheesecake chocolate tart cake gingerbread fruitcake cake
            candy jelly-o. Candy cookie lollipop. Wafer lemon drops chocolate
            cake gummi bears. -->
          </b-card-text>
        </b-tab>
        <!-- <b-tab title="Disabled" disabled>
          <b-card-text> Carrot cake dragée chocolate. </b-card-text>
        </b-tab> -->
      </b-tabs>

      <template #code>
        {{ codeDefault }}
      </template>
    </b-card>

    <!-- Filters -->
    <!-- <list-data-table-filters
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
    /> -->
  </div>
</template>

<script>
import {
  BCard,
  BImg,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BBadge,
  BTooltip,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useModuleList from "./useList";
import storeModule from "./storeModule";
// import ListDataTable from "./ListDataTable.vue";
import ListDataTableFilters from "./ListDataTableFilters.vue";
//table
import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
} from "bootstrap-vue";

// sweet alert
import Ripple from "vue-ripple-directive";
import { codeBasic } from "../../extensions/sweet-alert/code";
//
// notifications
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { codeIcon } from "../../extensions/toastification/code";

// tabs
import TabDefault from "./TabDefault.vue";
import BCardCode from "@core/components/b-card-code";
import { BForm, BFormGroup, BTabs, BTab, BCardText } from "bootstrap-vue";
import { codeDefault } from "./code";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    ListDataTableFilters,
    // ListDataTable, //

    BCard,
    BRow,
    BCol,
    BImg,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    //tabs
    TabDefault,
    BCardCode,
    BTabs,
    BCardText,
    BTab,
    // table
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
  },
  data() {
    return {
      codeIcon,
      presenceData: {},
      monthOptions: [],
      agentOptions: [],
      fichePresence: [],
      date_time: "",
      sumDays: 0,
      sumHours: 0,
      presenceDataLoaded: false,
    };
  },
  directives: {
    Ripple,
  },
  props: {},
  async mounted() {

    // 👇️ 24/07/2023 (DD/MM/YYYY)
    //console.log(formatDate(new Date()));

    //  👇️️ 24/07/2027 (DD/MM/YYYY)
    //console.log(formatDate(new Date(2027, 6, 24)));

    // year
    this.presenceData.year = new Date().getFullYear();
    // month options
    this.monthOptions.push({ label: "Janvier", value: "01" });
    this.monthOptions.push({ label: "Février", value: "02" });
    this.monthOptions.push({ label: "Mars", value: "03" });
    this.monthOptions.push({ label: "Avril", value: "04" });
    this.monthOptions.push({ label: "Mai", value: "05" });
    this.monthOptions.push({ label: "Juin", value: "06" });
    this.monthOptions.push({ label: "Juillet", value: "07" });
    this.monthOptions.push({ label: "Aout", value: "08" });
    this.monthOptions.push({ label: "Septembre", value: "09" });
    this.monthOptions.push({ label: "Octobre", value: "10" });
    this.monthOptions.push({ label: "Novembre", value: "11" });
    this.monthOptions.push({ label: "Décembre", value: "12" });

    // load agents list
    await myApi
      .get(`agent`)
      .then((response) => {
        // default action
        var data = response.data.data;
        var i = 0;
        for (i = 0; i < data.length; i++) {
          this.agentOptions.push({
            label:
              data[i].firstname +
              " " +
              data[i].lastname +
              " " +
              data[i].surname,
            value: data[i].id,
          });
        }
      })
      .catch((error) => {
        //
        console.log(error);
      });
  },
  methods:{
    showToast(header, message, variant, icon, position, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: header,
            icon,
            text: message,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },
    printPresenceFile(divID) {
      //
      var year = this.presenceData.year;
      var month = this.presenceData.month;
      var agent = this.presenceData.sku_agent;

      const now = new Date()
      // Grab the HH:mm:ss part of the time string
      var dateNow = now.toTimeString();

      // Get HTML to print from element
      const prtHtml = document.getElementById(divID).innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(
        `<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}
        </head>
        <body>
          <h1 style="padding-bottom:10px; border-bottom:2px solid black">FICHE DE PRESENCE DFJ</h1>
          <h1>Agent: <strong>` +
        agent + `</strong> ...................... Periode: <strong> ` + month +
        `/` +
        year +
        `</strong></h1>
          ${prtHtml} 
        </body>
      </html>`
      );

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    formatDate(isoDataTimeString) {
      var date = new Date(isoDataTimeString);
      var day = date.getDate();
      var year = date.getFullYear();
      var month = date.getMonth()+1;
      var dateStr = day+"/"+month+"/"+year;
      return dateStr;
    },
    async loadPresenceData() {
      // load agents list
      this.presenceDataLoaded = false;
      await myApi
        .get(
          $themeConfig.app.api_endpoint +
          `get_presence_file/${$themeConfig.app.api_endpoint_access_token}`,
          {
            params: this.presenceData,
          }
        )
        .then((response) => {
          // default action
          if (response.status == 200 || response.status == 201) {
            this.fichePresence = JSON.parse(JSON.stringify(response.data.data));
            this.sumDays = JSON.parse(JSON.stringify(response.data.sumDays));
            this.sumHours = JSON.parse(JSON.stringify(response.data.sumHours));
            this.date_time = JSON.parse(JSON.stringify(response.data.date_time));
            this.presenceDataLoaded = true;
          } else {
            this.showToast(
              "Erreur Survenu",
              response.data.message,
              "warning",
              "AlertTriangleIcon",
              "top-center",
              5000
            );
          }
        })
        .catch((err) => {
          //
          this.presenceDataLoaded = true;

          let errorMessage = "";
          if (!err?.response) {
            errorMessage = ("Serveur Introuvable, problème d'internet.");
          } else if (err.response?.status === 400) {
            errorMessage = ("Compte ou Mot de passe introuvable");
          } else if (err.response?.status === 401) {
            errorMessage = ("Accès Non Authorisé");
          } else {
            errorMessage = ("Echec de connexion");
          }

          console.log(err)

          this.showToast(
            "Erreur Exceptionnelle Survenu",
            errorMessage,
            "warning",
            "AlertTriangleIcon",
            "top-center",
            5000
          );

        });
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = "app-presence";

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME))
      store.registerModule(APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME))
        store.unregisterModule(APP_STORE_MODULE_NAME);
    });

    const isAddNewDataSidebarActive = ref(false);

    const typeOptions = [
      { label: "Matière Première", value: "first-way" },
      { label: "Produit Semi-Fini", value: "half-finished" },
      { label: "Produit Fini", value: "finished" },
      { label: "Produit Dérivé", value: "derived" },
      { label: "Consommables", value: "consummable" },
      { label: "Service", value: "service" },
      { label: "Autres", value: "other" },
    ];

    const statusOptions = [
      { label: "En Activité", value: "active" },
      { label: "Inactif", value: "inactive" },
      { label: "Non-Spécifié", value: "pending" },
    ];

    const resolvePresenceVariant = (direction) => {
      if (direction === "in") return "primary";
      if (direction === "out") return "warning";
      return "primary";
    };

    const resolvePresenceIcon = (direction) => {
      if (direction === "in") return "ArrowDownIcon";
      if (direction === "out") return "ArrowUpIcon";
      return "UserIcon";
    };

    const {
      fetchModulePresences,
      tableColumns,
      perPage,
      currentPage,
      totalDatas,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      refetchData,

      // UI
      resolveDataTypeVariant,
      resolveDataTypeIcon,
      resolveDataStatusVariant,

      // Extra Filters
      typeFilter,
      statusFilter,
    } = useModuleList();

    return {
      // Sidebar
      isAddNewDataSidebarActive,

      fetchModulePresences,
      tableColumns,
      perPage,
      currentPage,
      totalDatas,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolvePresenceIcon,
      resolvePresenceVariant,
      resolveDataTypeVariant,
      resolveDataTypeIcon,
      resolveDataStatusVariant,

      typeOptions,
      statusOptions,

      // Extra Filters
      typeFilter,
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
